import request from 'utils/request'
import { apiPrefix, apiURL } from 'utils/config'

import api from './api'

const gen = params => {
  let url = apiPrefix + params
  let method = 'GET'

  const paramsArray = params.split(' ')
  let isTokenAuthentication = false;
  if (paramsArray.length >= 2) {
    method = paramsArray[0]
    if (paramsArray.length >= 3 && paramsArray[2] == "URL") {
      url = apiURL + paramsArray[1];
      if (paramsArray.length === 4 && paramsArray[3] == "TOKEN") {
        isTokenAuthentication = true;
      }
    } else {
      url = apiPrefix + paramsArray[1]
    }
    
  }

  return function(data) {
    return request({
      url,
      data,
      method,
      isTokenAuthentication
    })
  }
}

const APIFunction = {}
for (const key in api) {
  APIFunction[key] = gen(api[key])
}

APIFunction.queryWeather = params => {
  params.key = 'i7sau1babuzwhycn'
  return request({
    url: `${apiPrefix}/weather/now.json`,
    data: params,
  })
}

export default APIFunction
