module.exports = {
  siteName: '',//'Hello Jack',
  copyright: 'Hello Jack CMS 2019',
  logoPath: '/Hello-Jack-Logo-e1582512762179.png',
  apiPrefix: '/api/v1',
  apiURL: 'https://api.hellojack.com.hk/index.php/api',
  chatApiURL: 'https://chat.hellojack.com.hk',
  // apiURL: 'http://localhost:8080/hellojack-api/index.php/api',
  fixedHeader: true, // sticky primary layout header

  /* Layout configuration, specify which layout to use for route. */
  layouts: [
    {
      name: 'primary',
      include: [/.*/],
      exclude: [/(\/(en|zh))*\/login/],
    },
  ],

  /* I18n configuration, `languages` and `defaultLanguage` are required currently. */
  i18n: {
    /* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
    languages: [
      // {
      //   key: 'pt-br',
      //   title: 'Português',
      //   flag: '/portugal.svg',
      // },
      {
        key: 'en',
        title: 'English',
        // flag: '/america.svg',
      },
      {
        key: 'zh',
        title: '繁體中文',
        // flag: '/china.svg',
      },
    ],
    defaultLanguage: 'en',
  },
}
