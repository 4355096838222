export const ROLE_TYPE = {
  ADMIN: 'admin',
  DEFAULT: 'admin',
  DEVELOPER: 'developer',
}

export const CANCEL_REQUEST_MESSAGE = 'cancel request'
export const API_STATUS_SUCCESS = 1
export const JOB_STATUSES = [
  { value: 'new', display: 'New' },
  { value: 'accepted', display: 'Accepted' },
  { value: 'deposit_done', display: 'Deposit Done' },
  { value: 're_scheduled', display: 'Re-scheduled' },
  { value: 'recommended', display: 'Evaluated' },
  { value: 'user_declined', display: 'User declined' },
  { value: 'in_progress', display: 'In progress' },
  { value: 'resolved', display: 'Resolved' },
  { value: 'balance_charge_failed', display: 'Balance Charge Failed' },
  // { 'value' : 'qa_checking' , 'display': 'QA checking'},
  // { 'value' : 'qa_passed' , 'display': 'Passed QA'},
  // { 'value' : 'qa_failed' , 'display': 'Failed QA'},
  // { 'value' : 'charging_done' , 'display': 'Charging Done'},
  { value: 'member_cancelled', display: 'Member cancelled' },
  { value: 'user_cancelled', display: 'User cancelled' },
  { value: 'completed', display: 'Completed' },
]
export const JOB_RENO_STATUSES = [
  { value: 'new', display: 'New' },
  { value: 'accepted', display: 'Accepted' },
  { value: 'in_progress', display: 'In progress' },
  { value: 'member_cancelled', display: 'Member cancelled' },
  { value: 'completed', display: 'Completed' },
]

export const JOB_STATUS_NEW = 'new'
export const JOB_STATUS_ACCEPTED = 'accepted'
export const JOB_STATUS_DEPOSIT_DONE = 'deposit_done'
export const JOB_STATUS_RE_SCHEDULED = 're_scheduled'
export const JOB_STATUS_RECOMMENDED = 'recommended'
export const JOB_STATUS_USER_DECLINED = 'user_declined'
export const JOB_STATUS_IN_PROGRESS = 'in_progress'
export const JOB_STATUS_RESOLVED = 'resolved'
export const JOB_STATUS_BALANCE_CHARGE_FAILED = 'balance_charge_failed'
export const JOB_STATUS_QA_CHECKING = 'qa_checking'
export const JOB_STATUS_QA_PASSED = 'qa_passed'
export const JOB_STATUS_QA_FAILED = 'qa_failed'
export const JOB_STATUS_CHARGING_DONE = 'charging_done'
export const JOB_STATUS_MEMBER_CANCELLED = 'member_cancelled'
export const JOB_STATUS_USER_CANCELLED = 'user_cancelled'
export const JOB_STATUS_COMPLETED = 'completed'

export const JOB_QA_PROGRESS = 'QA in progress'

export const JOB_RECORD_STATUSES = {
  new: 'New',
  accepted: 'Accepted',
  deposit_done: 'Deposit done',
  re_scheduled: 'Re-scheduled',
  recommended: 'Evaluated',
  user_declined: 'User declined',
  in_progress: 'In progress',
  resolved: 'Resolved',
  balance_charge_failed: 'Balance Charge Failed',
  // 'qa_checking' : 'QA checking',
  // 'qa_passed' : 'Passed QA',
  // 'qa_failed' : 'Failed QA',
  // 'charging_done' : 'Charging Done',
  member_cancelled: 'Member cancelled',
  user_cancelled: 'User cancelled',
  completed: 'Completed',
}
export const QA_STATUSES = [
  { value: 'all', display: 'All' },
  { value: 'required', display: 'Required' },
  { value: 'unassigned', display: 'Unassigned' },
  { value: 'qa_assigned', display: 'QA assigned' },
  { value: 'not_required', display: 'Not Required' },
]
export const HONGKONG_TIMEZONE = 'Asia/Hong_Kong'
export const CHAT_PAGE_SIZE = 10
export const CMS_VERSION = '1.0.0'

export const MEMBER_ROLE_HANDYMAN = 'handyman'
export const MEMBER_ROLE_ADMIN = 'admin'
export const ROLE_CUSTOMER = 'customer'
export const ROLE_SYSTEM = 'system'

export const RATING_NOT_YET = 0
export const RATING_BY_ADMIN = 1
export const RATING_BY_HOUSEHOLDER = 2
export const RATING_BY_HOUSEHOLDER_ADMIN = 3

export const APP_TYPES = [
  { value: 'ios', display: 'iOS' },
  { value: 'android', display: 'Android' },
]

export const APP_LOCALES = [
  { value: 'en', display: 'English' },
  { value: 'zh_t', display: 'Chinese Traditional' },
]

export const ENABLED_STATUSES = [
  { value: 'all', display: 'All' },
  { value: '1', display: 'Enabled' },
  { value: '0', display: 'Disabled' },
]

export const JOB_TYPE_REPAIR = 1
export const JOB_TYPE_RENO = 2
