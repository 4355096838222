export default {
  queryRouteList: '/routes',

  queryUserInfo: '/user',
  logoutUser: '/user/logout',
  loginUser: 'POST /user/login',

  queryUser: '/user/:id',
  queryUserList: '/users',
  updateUser: 'Patch /user/:id',
  createUser: 'POST /user',
  removeUser: 'DELETE /user/:id',
  removeUserList: 'POST /users/delete',

  queryPostList: '/posts',

  queryDashboard: '/dashboard',

  queryMemberList: 'POST /admin/list_members URL TOKEN',
  queryBudgetList: 'GET /service/list_renovation_budget URL',
  queryDistrictList: 'GET /district/list_districts URL',
  updateMember: 'POST /admin/update_member URL TOKEN',
  loginAdmin: 'POST /admin/login URL',
  queryJobList: 'POST /admin/list_jobs URL TOKEN',
  queryJobExportingList: 'POST /admin/list_jobs_exporting URL TOKEN',
  queryCompleteRenoList: 'POST /admin/list_complete_reno_jobs URL TOKEN',
  queryCompleteRenoExportingList:
    'POST /admin/list_complete_reno_jobs_exporting URL TOKEN',
  updateReno: 'POST /admin/update_reno URL TOKEN',
  updateRenoStatus: 'POST /admin/update_reno_status URL TOKEN',
  updateQC: 'POST /admin/update_qa URL TOKEN',
  updateJob: 'POST /admin/update_job URL TOKEN',
  completeJob: 'POST /admin/complete_job URL TOKEN',
  cancelJob: 'POST /admin/cancel_job URL TOKEN',
  queryDashboardInfo: 'GET /admin/dashboard URL TOKEN',
  queryNotifiedChatInfo: 'GET /admin/is_notified_chat URL TOKEN',
  updateNotifiedChatFlag: 'POST /admin/update_notified_chat URL TOKEN',
  queryCustomerList: 'POST /admin/list_customers URL TOKEN',
  querySpecialtyList: 'POST /admin/list_specialty URL TOKEN',
  createSpecialty: 'POST /admin/edit_specialty URL TOKEN',
  updateSpecialty: 'POST /admin/edit_specialty URL TOKEN',
  querySettingInfo: 'GET /admin/setting_info URL TOKEN',
  updateSetting: 'POST /admin/update_setting URL TOKEN',
  updateServicesPricing: 'POST /admin/update_services_pricing URL TOKEN',
  jsonHistoryInfo: 'GET /admin/json_history URL TOKEN',
  appVersionInfo: 'GET /admin/app_version_info URL TOKEN',
  updateAppVersion: 'POST /admin/update_app_version URL TOKEN',
  queryChatGroup: 'POST /chat/chat_group URL TOKEN',
  queryChatCompleteGroup: 'POST /chat/chat_complete_group URL TOKEN',
  queryPromotionList: 'POST /admin/list_promotions URL TOKEN',
  updatePromotion: 'POST /admin/update_promotion URL TOKEN',
  createPromotion: 'POST /admin/create_promotion URL TOKEN',
  queryCouponCodeList: 'POST /admin/list_coupons URL TOKEN',
  editCouponCode: 'POST /admin/edit_promotion_codes URL TOKEN',
}
