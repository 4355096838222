import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@tmp/history';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__index" */ '../../layouts/index.js'),
          LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
            .default,
        })
      : require('../../layouts/index.js').default,
    routes: [
      {
        path: '/404',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../404.js').default,
      },
      {
        path: '/:lang(en|zh)/404',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../404.js').default,
      },
      {
        path: '/UIElement/editor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__UIElement__editor__index" */ '../UIElement/editor/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../UIElement/editor/index.js').default,
      },
      {
        path: '/:lang(en|zh)/UIElement/editor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__UIElement__editor__index" */ '../UIElement/editor/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../UIElement/editor/index.js').default,
      },
      {
        path: '/applocalization',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__applocalization__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/applocalization/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__applocalization__index" */ '../applocalization/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../applocalization/index.js').default,
      },
      {
        path: '/:lang(en|zh)/applocalization',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__applocalization__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/applocalization/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__applocalization__index" */ '../applocalization/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../applocalization/index.js').default,
      },
      {
        path: '/appversion',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__appversion__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/appversion/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__appversion__index" */ '../appversion/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../appversion/index.js').default,
      },
      {
        path: '/:lang(en|zh)/appversion',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__appversion__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/appversion/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__appversion__index" */ '../appversion/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../appversion/index.js').default,
      },
      {
        path: '/chart/ECharts',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__chart__ECharts__index" */ '../chart/ECharts/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chart/ECharts/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chart/ECharts',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__chart__ECharts__index" */ '../chart/ECharts/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chart/ECharts/index.js').default,
      },
      {
        path: '/chart/Recharts',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__chart__Recharts__index" */ '../chart/Recharts/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chart/Recharts/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chart/Recharts',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__chart__Recharts__index" */ '../chart/Recharts/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chart/Recharts/index.js').default,
      },
      {
        path: '/chart/highCharts',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__chart__highCharts__index" */ '../chart/highCharts/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chart/highCharts/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chart/highCharts',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__chart__highCharts__index" */ '../chart/highCharts/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chart/highCharts/index.js').default,
      },
      {
        path: '/chat',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chat__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chat/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chat__index" */ '../chat/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chat/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chat',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chat__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chat/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chat__index" */ '../chat/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chat/index.js').default,
      },
      {
        path: '/chat/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chat__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chat/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__chat__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chat/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chat__$id__index" */ '../chat/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chat/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chat/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chat__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chat/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__chat__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chat/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chat__$id__index" */ '../chat/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chat/$id/index.js').default,
      },
      {
        path: '/chatcomplete',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chatcomplete__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chatcomplete/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chatcomplete__index" */ '../chatcomplete/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chatcomplete/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chatcomplete',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chatcomplete__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chatcomplete/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chatcomplete__index" */ '../chatcomplete/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chatcomplete/index.js').default,
      },
      {
        path: '/chatcomplete/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chatcomplete__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chatcomplete/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__chatcomplete__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chatcomplete/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chatcomplete__$id__index" */ '../chatcomplete/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chatcomplete/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/chatcomplete/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__chatcomplete__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chatcomplete/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__chatcomplete__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/chatcomplete/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__chatcomplete__$id__index" */ '../chatcomplete/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../chatcomplete/$id/index.js').default,
      },
      {
        path: '/completereno',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__completereno__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/completereno/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__completereno__index" */ '../completereno/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../completereno/index.js').default,
      },
      {
        path: '/:lang(en|zh)/completereno',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__completereno__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/completereno/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__completereno__index" */ '../completereno/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../completereno/index.js').default,
      },
      {
        path: '/completereno/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__completereno__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/completereno/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__completereno__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/completereno/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__completereno__$id__index" */ '../completereno/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../completereno/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/completereno/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__completereno__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/completereno/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__completereno__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/completereno/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__completereno__$id__index" */ '../completereno/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../completereno/$id/index.js').default,
      },
      {
        path: '/couponcode',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__couponcode__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/couponcode/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__couponcode__index" */ '../couponcode/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../couponcode/index.js').default,
      },
      {
        path: '/:lang(en|zh)/couponcode',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__couponcode__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/couponcode/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__couponcode__index" */ '../couponcode/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../couponcode/index.js').default,
      },
      {
        path: '/couponcode/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__couponcode__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/couponcode/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__couponcode__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/couponcode/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__couponcode__$id__index" */ '../couponcode/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../couponcode/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/couponcode/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__couponcode__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/couponcode/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__couponcode__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/couponcode/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__couponcode__$id__index" */ '../couponcode/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../couponcode/$id/index.js').default,
      },
      {
        path: '/customer',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__customer__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/customer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__customer__index" */ '../customer/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../customer/index.js').default,
      },
      {
        path: '/:lang(en|zh)/customer',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__customer__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/customer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__customer__index" */ '../customer/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../customer/index.js').default,
      },
      {
        path: '/customer/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__customer__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/customer/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__customer__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/customer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__customer__$id__index" */ '../customer/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../customer/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/customer/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__customer__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/customer/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__customer__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/customer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__customer__$id__index" */ '../customer/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../customer/$id/index.js').default,
      },
      {
        path: '/dashboard',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__dashboard__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/dashboard/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__dashboard__index" */ '../dashboard/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../dashboard/index.js').default,
      },
      {
        path: '/:lang(en|zh)/dashboard',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__dashboard__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/dashboard/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__dashboard__index" */ '../dashboard/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../dashboard/index.js').default,
      },
      {
        path: '/',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__index" */ '../index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../index.js').default,
      },
      {
        path: '/:lang(en|zh)/',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__index" */ '../index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../index.js').default,
      },
      {
        path: '/job',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__job__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/job/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__job__index" */ '../job/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../job/index.js').default,
      },
      {
        path: '/:lang(en|zh)/job',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__job__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/job/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__job__index" */ '../job/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../job/index.js').default,
      },
      {
        path: '/job/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__job__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/job/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__job__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/job/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__job__$id__index" */ '../job/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../job/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/job/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__job__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/job/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__job__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/job/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__job__$id__index" */ '../job/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../job/$id/index.js').default,
      },
      {
        path: '/login',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__login__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/login/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__login__index" */ '../login/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../login/index.js').default,
      },
      {
        path: '/:lang(en|zh)/login',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__login__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/login/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__login__index" */ '../login/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../login/index.js').default,
      },
      {
        path: '/memberspecialty',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__memberspecialty__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/memberspecialty/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__memberspecialty__index" */ '../memberspecialty/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../memberspecialty/index.js').default,
      },
      {
        path: '/:lang(en|zh)/memberspecialty',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__memberspecialty__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/memberspecialty/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__memberspecialty__index" */ '../memberspecialty/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../memberspecialty/index.js').default,
      },
      {
        path: '/memberspecialty/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__memberspecialty__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/memberspecialty/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__memberspecialty__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/memberspecialty/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__memberspecialty__$id__index" */ '../memberspecialty/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../memberspecialty/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/memberspecialty/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__memberspecialty__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/memberspecialty/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__memberspecialty__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/memberspecialty/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__memberspecialty__$id__index" */ '../memberspecialty/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../memberspecialty/$id/index.js').default,
      },
      {
        path: '/post',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__post__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/post/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__post__index" */ '../post/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../post/index.js').default,
      },
      {
        path: '/:lang(en|zh)/post',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__post__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/post/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__post__index" */ '../post/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../post/index.js').default,
      },
      {
        path: '/pricing',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__pricing__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/pricing/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__pricing__index" */ '../pricing/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../pricing/index.js').default,
      },
      {
        path: '/:lang(en|zh)/pricing',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__pricing__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/pricing/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__pricing__index" */ '../pricing/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../pricing/index.js').default,
      },
      {
        path: '/promotion',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__promotion__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/promotion/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__promotion__index" */ '../promotion/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../promotion/index.js').default,
      },
      {
        path: '/:lang(en|zh)/promotion',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__promotion__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/promotion/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__promotion__index" */ '../promotion/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../promotion/index.js').default,
      },
      {
        path: '/promotion/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__promotion__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/promotion/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__promotion__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/promotion/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__promotion__$id__index" */ '../promotion/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../promotion/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/promotion/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__promotion__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/promotion/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__promotion__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/promotion/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__promotion__$id__index" */ '../promotion/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../promotion/$id/index.js').default,
      },
      {
        path: '/request',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__request__index" */ '../request/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../request/index.js').default,
      },
      {
        path: '/:lang(en|zh)/request',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__request__index" */ '../request/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../request/index.js').default,
      },
      {
        path: '/tobeqc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__tobeqc__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/tobeqc/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__tobeqc__index" */ '../tobeqc/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../tobeqc/index.js').default,
      },
      {
        path: '/:lang(en|zh)/tobeqc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__tobeqc__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/tobeqc/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__tobeqc__index" */ '../tobeqc/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../tobeqc/index.js').default,
      },
      {
        path: '/tobeqc/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__tobeqc__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/tobeqc/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__tobeqc__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/tobeqc/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__tobeqc__$id__index" */ '../tobeqc/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../tobeqc/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/tobeqc/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__tobeqc__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/tobeqc/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__tobeqc__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/tobeqc/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__tobeqc__$id__index" */ '../tobeqc/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../tobeqc/$id/index.js').default,
      },
      {
        path: '/user',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/user/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__index" */ '../user/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../user/index.js').default,
      },
      {
        path: '/:lang(en|zh)/user',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/user/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__index" */ '../user/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../user/index.js').default,
      },
      {
        path: '/user/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/user/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__user__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/user/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__$id__index" */ '../user/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../user/$id/index.js').default,
      },
      {
        path: '/:lang(en|zh)/user/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__$id__models__detail.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/user/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__user__model.js' */ '/var/www/html/hellojack-admin-prod/hellojack-admin/src/pages/user/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__$id__index" */ '../user/$id/index.js'),
              LoadingComponent: require('/var/www/html/hellojack-admin-prod/hellojack-admin/src/components/Loader/Loader')
                .default,
            })
          : require('../user/$id/index.js').default,
      },
      {
        component: () =>
          React.createElement(
            require('/var/www/html/hellojack-admin-prod/hellojack-admin/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: false },
          ),
      },
    ],
  },
  {
    component: () =>
      React.createElement(
        require('/var/www/html/hellojack-admin-prod/hellojack-admin/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
          .default,
        { pagesPath: 'src/pages', hasRoutesInConfig: false },
      ),
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen = () => {};

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    routeChangeHandler(history.location);
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
